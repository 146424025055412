import {AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { LayoutService } from "./service/app.layout.service";
import { AppSidebarComponent } from "./app.sidebar.component";
import { AppTopBarComponent } from './app.topbar.component';
import { AuthService } from '../core/services/auth.service';
import { AppEndPoint } from '../EndPointUrls/appEndPoints.enum';
import { __Permissions } from '../core/permissionEnum/permissions';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-layout',
    templateUrl: './app.layout.component.html',
})
export class AppLayoutComponent implements OnDestroy,OnInit {

    overlayMenuOpenSubscription: Subscription;

    menuOutsideClickListener: any;

    profileMenuOutsideClickListener: any;

    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

    @ViewChild(AppTopBarComponent) appTopbar!: AppTopBarComponent;


    constructor(private cdref: ChangeDetectorRef,public authService:AuthService,public layoutService: LayoutService, public renderer: Renderer2, public router: Router) {
        this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
            if (!this.menuOutsideClickListener) {
                this.menuOutsideClickListener = this.renderer.listen('document', 'click', event => {
                    const isOutsideClicked = !(this.appSidebar.el.nativeElement.isSameNode(event.target) || this.appSidebar.el.nativeElement.contains(event.target)
                        || this.appTopbar.menuButton.nativeElement.isSameNode(event.target) || this.appTopbar.menuButton.nativeElement.contains(event.target));

                    if (isOutsideClicked) {
                        this.hideMenu();
                    }
                });
            }

            if (!this.profileMenuOutsideClickListener) {
                this.profileMenuOutsideClickListener = this.renderer.listen('document', 'click', event => {
                    const isOutsideClicked = !(this.appTopbar.menu.nativeElement.isSameNode(event.target) || this.appTopbar.menu.nativeElement.contains(event.target)
                        || this.appTopbar.topbarMenuButton.nativeElement.isSameNode(event.target) || this.appTopbar.topbarMenuButton.nativeElement.contains(event.target));

                    if (isOutsideClicked) {
                        this.hideProfileMenu();
                    }
                });
            }

            if (this.layoutService.state.staticMenuMobileActive) {
                this.blockBodyScroll();
            }
        });


        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.hideMenu();
                this.hideProfileMenu();
            });
    }
    ngOnInit(): void {
        let url = sessionStorage.getItem("requested_url")?.replace(environment.AUTH_REDIRECT_URI, "");
        sessionStorage.removeItem("requested_url");
        
        this.authService.loadUserInformation().then(() => {
            if (this.authService.hasPermission(__Permissions.documentList)===true)
            {
            this.authService.getTaskCount().subscribe(
                (data: any) => {
                    this.authService.userDataAvailable = true;
                    
                    if (url == "/" || url == "" ) { 
                        if (data?.result > 0) {
                            this.router.navigate([AppEndPoint.Documents]);
                        } else {
                            this.router.navigate([AppEndPoint.Home]);
                        }
                    } else {
                        if (url) {
                            if (url.includes('signin-oidc')) {
                                // this.authService.navigate_to_requested_url()
                                if (data?.result > 0) {
                                    this.router.navigate([AppEndPoint.Documents]);
                                } else {
                                    this.router.navigate([AppEndPoint.Home]);
                                }

                            } else {
                                this.router.navigateByUrl(url);
                            }
                        }
                    }

                    // if (data?.result > 0) {
                    //     if (url == "/" || url == "" ) {
                    //         this.router.navigate([AppEndPoint.Documents]);
                    //     } else {
                    //         this.router.navigateByUrl(url);
                    //     }                    // } else {
                    //     if (url == "/" || url == "" ) {
                    //         this.router.navigate([AppEndPoint.Home]);
                    //     } else {
                    //         this.router.navigateByUrl(url);
                    //     }
                    // }
                })
        }
        else {
            this.router.navigate([AppEndPoint.BlockOrganization]);
            if ( this.authService.loggedInUser.bill.status === 'Blocked') {
                this.authService.showPermissionContent=false;
            }else{
                this.authService.showPermissionContent=true;
            }
        }
        }) 

        // this.hidePreloader();
    }


    hideMenu() {
        this.layoutService.state.overlayMenuActive = false;
        this.layoutService.state.staticMenuMobileActive = false;
        this.layoutService.state.menuHoverActive = false;
        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
            this.menuOutsideClickListener = null;
        }
        this.unblockBodyScroll();
    }

    hideProfileMenu() {
        this.layoutService.state.profileSidebarVisible = false;
        if (this.profileMenuOutsideClickListener) {
            this.profileMenuOutsideClickListener();
            this.profileMenuOutsideClickListener = null;
        }
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    get containerClass() {
        
        return {
            'layout-theme-light': this.layoutService.config.colorScheme === 'light',
            'layout-theme-dark': this.layoutService.config.colorScheme === 'dark',
            'layout-overlay': this.layoutService.config.menuMode === 'overlay',
            'layout-static': this.layoutService.config.menuMode === 'static',
            'layout-static-inactive': this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
            'layout-overlay-active': this.layoutService.state.overlayMenuActive,
            'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
            'p-input-filled': this.layoutService.config.inputStyle === 'filled',
            'p-ripple-disabled': !this.layoutService.config.ripple
        }
    }


    hidePreloader(){
        var Preloader = document.getElementById("pre_loader");
        // Change the style to hide the element
        Preloader.style.display = "none";
    }
    ngOnDestroy() {
        if (this.overlayMenuOpenSubscription) {
            this.overlayMenuOpenSubscription.unsubscribe();
        }

        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
        }
    }
}
