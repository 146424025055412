// import { OAuthService } from 'angular-oauth2-oidc';
import { Component } from '@angular/core';

@Component({
  selector: 'app-log-out.ts',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss']
})
export class LogOutComponent {
    constructor(
      // private oauth:OAuthService
      ){}

  ngOnInit(){
    localStorage.clear();
    sessionStorage.clear();
    // this.oauth.logOut();
  }

}
