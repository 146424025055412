import { Component, OnInit, Input, Output, Injector, Inject } from '@angular/core';;
import { BaseComponent } from 'src/app/shared/components/baseComponent';
import { TreeNode } from 'primeng/api';
import * as _ from 'lodash';
import { DocumentAIService } from 'src/app/shared/services/documentAI.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-edit',
  templateUrl: './create-edit.component.html',
  styleUrls: ['./create-edit.component.scss']
})
export class CreateEditLibraryComponent extends BaseComponent implements OnInit {

  type: 'edit' | 'create' = 'create';
  formGroup !: FormGroup;
  constructor(injector: Injector,
    public service: DocumentAIService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateEditLibraryComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {
    super(injector, '');
    
    if (modalData) {
      console.log(this.modalData);
      this.type = 'edit'
  }
  this.createForm();
}
ngOnInit(): void {
  this.setFormValues();
}

private setFormValues() {
  let expireOnDate ;
 if(this.modalData.expiresOn  != null){
  expireOnDate =  new Date(this.modalData.expiresOn);
 }
  
  this.getCtrl('name').setValue(this.modalData.name || '');
  this.getCtrl('description').setValue(this.modalData.description || '');
  this.getCtrl('expiresOn').setValue(expireOnDate || '');
  this.getCtrl('id').setValue(this.modalData.id || '');
}

private createForm() {
  this.formGroup = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(128)]],
      expiresOn: [''],
      description: [''],
  });
}

save() {
  if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
  }

  let data = this.formGroup.getRawValue();
  if(data.description == ''){
    data.description = null;
  }
  if(data.expiresOn == ''){
    data.expiresOn = null; 
  }
  if(data.id == ''){
    data.id = null;
  }

  this.service.createNewLibrary(data).subscribe(
      (a:any) => {
      if (this.type ==='create') {
        this.showSuccessToaster('Saved Successfully', '')
      }
       else if (this.type ==='edit') {
        this.showSuccessToaster('Updated Successfully', '')
       }
       this.closePopup(true)
      }
  )
}


getCtrl = (name: string) => {
  return this.formGroup.controls[name] as FormControl
}

  closePopup(modified = false) {
    this.dialogRef.close(modified);
  }
}
