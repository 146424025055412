import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { OpenbotsNgLibraryModule } from 'ob-ng-library';
import { TooltipModule } from 'primeng/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgxUploaderModule } from 'ngx-uploader';
import { FileUploaderComponent } from './components/fileUploader/fileUploader.component';
import { FileSizePipe } from './customPipes/fileSize.pipe';
import { MomentFormatPipe } from './customPipes/momentFormat.pipe';
import { CastStatusPipe } from './customPipes/statusAlter.pipe';
import { DeletePopupComponent } from './components/delete-popup/delete-popup.component';
import { DialogModule } from 'primeng/dialog';
import { PermissionTreeComponent } from '../administration/user/components/permission-tree/permission-tree.component';
import { TreeModule } from 'primeng/tree';
import { CreateOrEditRoleModalComponent } from '../administration/roles/components/create-or-edit-role-modal.component';
import { FormlyCaseInputComponent } from './components/formlyComponents/formly-case-input/formly-case-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PositiveIntegerDirective } from './positiveInteger.directive';
import { AuthorizedDirective } from './authorized.directive';
import {
    constValidationMessage,
    exclusiveMaximumValidationMessage,
    exclusiveMinimumValidationMessage,
    maxItemsValidationMessage,
    maxLengthValidationMessage,
    maxValidationMessage,
    minItemsValidationMessage,
    minLengthValidationMessage,
    minValidationMessage,
    multipleOfValidationMessage,
} from './formlyfunctions';
import { MultiSchemaTypeComponent } from './components/formlyComponents/multi-schema-type/multi-schema-type.component';
import { ArrayTypeComponent } from './components/formlyComponents/arraytype/arraytype.component';
import { FormlyInputComponent } from './components/formlyComponents/formly-input/formly-input.component';
import { FormlyJsonOutputComponent } from './components/formlyComponents/formly-json-output/formly-json-output.component';
import { FormallySelectComponent } from './components/formlyComponents/formally-select/formally-select.component';
import { FormlyButtonComponent } from './components/formlyComponents/formly-button/formly-button.component';
import { FormlyFileInputComponent } from './components/formlyComponents/formly-file-input/formly-file-input.component';
import { FormlyImageOutputComponent } from './components/formlyComponents/formly-image-output/formly-image-output.component';
import { FormlyTextOutputComponent } from './components/formlyComponents/formly-text-output/formly-text-output.component';
import { FormlyTextareaComponent } from './components/formlyComponents/formly-textarea/formly-textarea.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { AngularMarkdownEditorModule } from 'angular-markdown-editor';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { FormlyModule } from '@ngx-formly/core';
import { SeeMoreLessComponent } from './components/see-more-less/see-more-less.component';
import { TableModule } from 'primeng/table';
import { FormlyDatePickerComponent } from './components/formlyComponents/formly-date-picker/formlyDatePicker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TestingTableComponent } from './components/ob-table-Testing/ob-table-Testing.component';
import { JsonLogicEditorModule } from './components/testing-json-logic/json-logic-editor.module';
import { CreateEditLibraryComponent } from '../administration/components/Libraries/components/create-edit/create-edit.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { AccordionModule } from 'primeng/accordion';
import { FormlyTextInputComponent } from './components/formlyComponents/formly-text-input/formly-text-input.component';
import { FormlyCheckboxComponent } from './components/formlyComponents/formly-checkbox/formly-checkbox.component';
import { FormlyCustomTooltipComponent } from './components/formlyComponents/FormlyCustomTooltip/FormlyCustomTooltip.component';
import { OrganizationBlockedComponent } from './components/organization-blocked/organization-blocked.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TrimTextDirective } from './trimtext.directive';
import { ConfirmationDialogPopupComponent } from './components/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { TextAreaCanvasComponent } from './components/textAreaCanvas/textAreaCanvas.component';
import { GroupByPipe } from './customPipes/groupby.pipe';
import { SliderModule } from 'primeng/slider';
import { PublishCommentsComponent } from '../administration/components/template-history/publish-comments/publish-comments.component';

@NgModule({
    imports: [
        CommonModule,
        InputSwitchModule,
        SliderModule,
        OpenbotsNgLibraryModule,
        NgxUploaderModule,
        DialogModule,
        TableModule,
        InputTextareaModule,
        NgxJsonViewerModule,
        ReactiveFormsModule,
        JsonLogicEditorModule,
        FormlyPrimeNGModule,
        CardModule,
        MatFormFieldModule,
        MatInputModule ,
        AccordionModule,
        MatDatepickerModule,
        TreeModule,
        TabViewModule,
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useValue: {
                    renderer: new MarkedRenderer(),
                    gfm: true,
                    tables: false,
                    breaks: true,
                    pedantic: false,
                    sanitize: false,
                    smartLists: false,
                    smartypants: false,
                },
            },
        }),
        AngularMarkdownEditorModule.forRoot({ iconlibrary: 'fa' }),
        FormlyModule.forRoot({
            validationMessages: [
                { name: 'required', message: 'This field is required' },
                // { name: 'type', message: typeValidationMessage },
                { name: 'minLength', message: minLengthValidationMessage },
                { name: 'maxLength', message: maxLengthValidationMessage },
                { name: 'min', message: minValidationMessage },
                { name: 'max', message: maxValidationMessage },
                { name: 'multipleOf', message: multipleOfValidationMessage },
                { name: 'exclusiveMinimum', message: exclusiveMinimumValidationMessage },
                {
                    name: 'exclusiveMaximum',
                    message: exclusiveMaximumValidationMessage,
                },
                { name: 'minItems', message: minItemsValidationMessage },
                { name: 'maxItems', message: maxItemsValidationMessage },
                {
                    name: 'uniqueItems',
                    message: 'should NOT have duplicate items',
                },
                { name: 'const', message: constValidationMessage },
            ],

            types: [
                // {name: 'Input', component: FormlyCaseInputComponent},
                { name: 'object', extends: 'formly-group' },
                { name: 'multischema', component: MultiSchemaTypeComponent },
                { name: 'array', component: ArrayTypeComponent },
                { name: 'string', component: FormlyInputComponent },
                // { name: 'Text', component: FormlyTextInputComponent},
                { name: 'json', component: FormlyJsonOutputComponent },
                // { name: 'checkbox', component: FormlyCheckboxComponent },
                //FormalyDatePicker
            ],
        }),
    ],
    declarations: [	
        TextAreaCanvasComponent,
        ErrorMessageComponent,
        FileUploaderComponent,
        DeletePopupComponent,
        FileSizePipe,
        GroupByPipe,
        CastStatusPipe,
        MomentFormatPipe,
        TestingTableComponent,
        FormlyCaseInputComponent,
        AuthorizedDirective,
        TrimTextDirective,
        PositiveIntegerDirective,
        MultiSchemaTypeComponent,
        ArrayTypeComponent,
        FormlyInputComponent,
        FormlyTextareaComponent,
        FormlyFileInputComponent,
        FormlyButtonComponent,
        FormallySelectComponent,
        FormlyImageOutputComponent,
        FormlyTextOutputComponent,
        FormlyJsonOutputComponent,
        FormlyDatePickerComponent,
        SeeMoreLessComponent,
        PermissionTreeComponent,
        CreateOrEditRoleModalComponent,
        NoAccessComponent,
        OrganizationBlockedComponent,
        CreateEditLibraryComponent,
        FormlyTextInputComponent,
        FormlyCheckboxComponent,
      PositiveIntegerDirective,
      ConfirmationDialogPopupComponent,
      PublishCommentsComponent
   ],
    exports: [
        TextAreaCanvasComponent,
        TableModule,
        InputSwitchModule ,
        SliderModule,
        FormlyModule,
        CardModule,
        FileSizePipe,
        CastStatusPipe,
        MomentFormatPipe,
        ErrorMessageComponent,
        FileUploaderComponent,
        DeletePopupComponent,
        NgxUploaderModule,
        OpenbotsNgLibraryModule,
        TooltipModule,
        MatDatepickerModule,
        MatInputModule,
        TestingTableComponent,
        FormlyCaseInputComponent,
        AuthorizedDirective,
        TrimTextDirective,
        PositiveIntegerDirective,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule,
        CheckboxModule,
        ToastModule,
        TabViewModule,
        NgxJsonViewerModule,
        ArrayTypeComponent,
        FormlyInputComponent,
        DialogModule,
        AccordionModule,
        FormlyFileInputComponent,
        FormlyButtonComponent,
        FormallySelectComponent,
        FormlyImageOutputComponent,
        FormlyTextOutputComponent,
        FormlyJsonOutputComponent,
        FormlyTextareaComponent,
        SeeMoreLessComponent,
        JsonLogicEditorModule,
        MatFormFieldModule,
        MatInputModule ,
        MatDatepickerModule,
        FormlyDatePickerComponent,
        TreeModule,
        PermissionTreeComponent,
        CreateOrEditRoleModalComponent,
        CreateEditLibraryComponent,
        NoAccessComponent,
        ConfirmationDialogPopupComponent,
        PublishCommentsComponent
    ],
    
})
export class SharedModule {}
