<ng-container class="linemian" id="parent" *ngTemplateOutlet="recursivecomponent;context: {component:form}">
</ng-container>
<ng-template #recursivecomponent let-componentForm="component">
    <form class="componentclass" [formGroup]="componentForm">
        <table [ngClass]="{'empthytable': componentForm.get('controlType')?.value===null}" class="rowm" [class]="'line'+componentForm.get('padding')?.value+'px'"
            [style.margin-left]="componentForm.get('padding')?.value+'px'" style="width: 100%; overflow-x: scroll;">
            <!-- <table class="rowm" style="width: 100%; overflow-x: scroll;"> -->
              <tr>
                <td>
            <div *ngIf="componentForm.get('controlType')?.value===null" class="emptycontrol emptypadding">
            <p class="emptyspace"> {{this.getPlaceHolder(componentForm)==="Select null"?"":this.getPlaceHolder(componentForm)}}</p>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </div>
          </td>
              <td>
            <div class="col-md-6m paddingleftm inputwidth" *ngIf="componentForm.get('controlType')?.value==='value'">
                <!-- <input  [placeholder]="this.getPlaceHolder(componentForm)" [type]="componentForm.get('type')?.value"
                    class="form-control" placeholder="Enter Some Value" formControlName="value" /> -->
                <div *ngIf="componentForm.get('type')?.value!=='date'">
                    <input [id]="componentForm.get('padding')?.value+componentForm.get('name')?.value" [placeholder]="this.getPlaceHolder(componentForm)" [type]="componentForm.get('type')?.value"
                        class="form-controls" placeholder="Enter Some Value" formControlName="value" />
                </div>
                <div *ngIf="componentForm.get('type')?.value==='date'">
                    <input [id]="componentForm.get('padding')?.value+componentForm.get('name')?.value" (keydown)="disableDateEntry($event,expressionDatePicker)" class="form-controls m-input"
                        type="datetime" bsDatepicker #expressionDatePicker="bsDatepicker" formControlName="value"
                        [bsConfig]="{dateInputFormat:'YYYY-MM-DD'}" />
                </div>
            </div>
          </td
          >
          <td>
            <div class="col-md-6m paddingleftm"
                *ngIf="componentForm.get('controlType')?.value==='variable'  || componentForm.get('controlType')?.value==='function'">
                <ng-select [ngClass]="{errorborder: this.checkerror(componentForm.get('value'))}" [id]="componentForm.get('padding')?.value+componentForm.get('name')?.value" class="form-controls" [items]="filtered_fieldsList" (open)="filterlist(componentForm)"
                    (change)="onSelect(componentForm,componentForm.get('controlType')?.value)" bindLabel="label"
                    [clearable]="false" bindValue="value" [placeholder]="this.getPlaceHolder(componentForm)"
                    formControlName="value">
                </ng-select>
            </div>
          </td>
            <!-- *ngIf="componentForm.get('type')?.value!=null" -->
            <td (click)="changeToValue(componentForm)">
            <div *ngIf="showValueTypeControl(componentForm)" class="col-md-2m"[ngClass]="{selectedIcon: componentForm.get('controlType')?.value==='value'}">


              <!-- <i (click)="changeToValue(componentForm)" class="fa fa-keyboard" aria-hidden="true"></i> -->

              <img [src]="keyboard" [id]="componentForm.get('padding')?.value+componentForm.get('name')?.value" class="imgvar imgtyping" />

            </div>
          </td>

          <td (click)="changeToVariable(componentForm)">
            <div  class="col-md-2m" [ngClass]="{selectedIcon: componentForm.get('controlType')?.value==='variable'}">

                    <img [src]="variable" [id]="componentForm.get('padding')?.value+componentForm.get('name')?.value" class="imgvar" />
            </div>
          </td>

          <td (click)="changeToFunction(componentForm)">
            <div [ngClass]="{errorborder: this.checkerror(componentForm.get('controlType'))}" class="col-md-2m" [ngClass]="{selectedIcon: componentForm.get('controlType')?.value==='function'}">

                    <img [src]="function"  [id]="componentForm.get('padding')?.value+componentForm.get('name')?.value" class="imgvar imgfunc" />
            </div>
          </td>
          </tr>
          </table>
        <div class="lineone" *ngIf="componentForm.get('parameters')?.value!==undefined">
            <div class="linetwo" *ngFor="let group of componentForm.get('parameters')['controls'];let i=index">
                <ng-container class="linethree" *ngTemplateOutlet="recursivecomponent;context: {component: group}">
                </ng-container>
            </div>
        </div>
    </form>
</ng-template>
