import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BillingStatus } from '../interfaces/billingstatus';
import { AuthService } from '../core/services/auth.service';
import { LayoutService } from "./service/app.layout.service";
import { AppConsts } from 'src/app/core/constraints/appConsts';
import { environment } from 'src/environments/environment';
import { MenuItem } from 'primeng/api';
import { DocumentAIService } from '../shared/services/documentAI.service';
import { event } from 'jquery';
// import { DocumentsComponent } from '../document/components/documents/documents.component';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    
})
export class AppTopBarComponent implements OnInit{
    showRightPanel:boolean=false;
    bill?:BillingStatus;
    selectedOrganization: any;
    displayTaskName :string | undefined
    items: MenuItem[] | undefined;
    tabDisplayClass : boolean = false;
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
    @ViewChild('topbarmenu') menu!: ElementRef;
    contactUsUrl = AppConsts.contactUsLink;
    constructor(public layoutService: LayoutService,public authService:AuthService,public router:Router ,public service : DocumentAIService , private renderer: Renderer2) {
       
    }
    ngOnInit(): void {
        //  this.service.filterDocumentName.subscribe((taskName)=>{
        //     this.displayTaskName = taskName
        // });
        this.selectedOrganization = this.authService.loggedInUser.myOrganizations.find(org => org.tenantKey === this.authService.loggedInUser.activeTenantKey);
        this.items = [
            // {
            //     label: 'Admin',
            //     icon: '../../assets/layout/images/admin.jpg',
            //     visible: this.authService.hasPermission('Pages'),
            //     command:(event)=>{
            //         console.log(event);
            //     }
            //     // visible: truew
            // },
            // {
            //     label: 'Contributor',
            //     icon: '../../assets/layout/images/collaborator.jpg',
            //     visible: true

            // },
            // {
            //     label: 'Developer',
            //     icon: '../../assets/layout/images/developer.jpg',
            //     visible: true
            // },
        ];
        this.service.filterDocumentName.subscribe((data) => {
            this.displayTaskName = data
          })

          console.log('azure b2c');
          
    }
    OnChangeOrganization($event:any)
    {
        localStorage.setItem("organization_tenant_key", this.authService.loggedInUser.activeTenantKey );
        this.authService.loggedInUser.userPermissions=[];
        this.authService.set_UserPermissions_And_BillingStatus(true);
    }
    logout(){
        this.authService.logout();
    }
    manegeSubscriptions(){
        let cloud_dashboard_url=environment.cloud_dashbord_link;
        window.open(cloud_dashboard_url, "_blank");
    }
    gotoSupport() {
        let contactUs = environment.supportlink
        window.open(contactUs, '_blank');
      }
      gotoHome() {
        if(this.authService.loggedInUser.bill?.status == 'Blocked') {
            return;
        }
        this.router.navigate(['document/create'])
      }
      AddClass(){
        this.tabDisplayClass = !this.tabDisplayClass
        console.log(this.tabDisplayClass)
        if(this.tabDisplayClass === true){
            const tabPanels = document.querySelector('.p-tabview-panels');
            this.renderer.addClass(tabPanels, 'tabDisplayBlockMenu');

            const openmenu = document.querySelector('.imageUploadDoc');
            this.renderer.addClass(openmenu, 'menu-open-image');
            const layoutWrapperElement = document.querySelector('.layout-wrapper');
            this.renderer.addClass(layoutWrapperElement, 'layout-static-inactive');
            
        }else{
            const tabPanels = document.querySelector('.p-tabview-panels');
            this.renderer.removeClass(tabPanels, 'tabDisplayBlockMenu');

            const openmenu = document.querySelector('.imageUploadDoc');
            
            this.renderer.removeClass(openmenu, 'menu-open-image');

            const layoutWrapperElement = document.querySelector('.layout-wrapper');
            this.renderer.removeClass(layoutWrapperElement, 'layout-static-inactive');
           

        }
      }
      
}
