export enum HUMAN_TASK {
    HumanTask = '/HumanTasks',
}
export enum HUMAN_TASKS_EndPoints {
    GetAll = '/GetAll',
    GetAllUserForTableDropdown = '/GetAllUserForTableDropdown',
    Delete = '/Delete',
    ReProcessTask = '/ReProcessTask',
    MultipleDelete = '/MultipleDelete',
    AssignDueOn = '/AssignDueOn',
    Reassign = '/Reassign',
    GetHumanTaskForView = '/GetHumanTaskForView',
    GetAttachments = '/GetAttachments',
    GetDownloadFile = '/GetDownloadFile',
    CreateOrEdit = '/CreateOrEdit',
    GetPendingTaskCount='/GetPendingTaskCount',
    GetTaskCount='/GetTaskCount',
    GetAllDocument ='/GetAllDocument'
}
export enum DOCUMENT_PROCESSING_ENGINE_SERVICE {
    DocumentProcessingEngineService = '/DocumentProcessingEngineService',
}
export enum DOCUMENT_PROCESSING_ENGINE_SERVICE_EndPoints {
    SubmitDocumentsWithDetails = '/SubmitDocumentsWithDetails',
    GetStatus = '/GetStatus',
    GetDocuments = '/GetDocuments',
    GetDocumentData = '/GetDocumentData',
    GetPageImage = '/GetPageImage',
    GetPageText = '/GetPageText',
    GetTenantIdsByKey = '/GetTenantIdsByKey',
    Chat = '/Chat',
    AssignTo='/AssignTo',
    GetAllForms ='/GetAllForms',
    SaveDocumentData = '/SaveDocumentData',
    MarkDocumentAsVerified='/MarkDocumentAsVerified',
    MarkDocumentAsSkipped = '/MarkDocumentAsSkipped'
}
export enum TemplateLibraries {
    TemplateLibraries = '/TemplateLibraries',
    DeleteTemplateLibrary = '/TemplateLibraries/Delete'
}
export enum TemplateLibraries_EndPoints {
    Getall = '/GetAll',
    GetTemplateLibraryForView = '/GetTemplateLibraryForView',
    CreateOrEdit = '/CreateOrEdit',
}
export enum TASK_QUEUES {
    TaskQueues = '/TaskQueues',
}
export enum FormClassifications {
    FormClassifications = '/FormClassifications',
}


export enum Form_Classifications_EndPoints {
    CreateOrEdit = '/CreateOrEdit',

}
export enum Checklist_Templates {
    GetAll= '/ChecklistTemplates/GetAll',
    GetAllForms = '/DocumentProcessingEngineService/GetAllForms',
    ExportExcel = '/ChecklistTemplates/GetChecklistTemplatesToExcel',
    Create_Edit = '/ChecklistTemplates/CreateOrEdit',
    Get_Checklist = '/ChecklistTemplates/GetChecklistTemplateForEdit',
    Delete = '/ChecklistTemplates/Delete'
}

export enum TASK_QUEUES_EndPoints {
    GetAll = '/GetAll',
    Delete = '/Delete',
    GetTaskQueuesToExcel = '/GetTaskQueuesToExcel',
    GetTaskQueueForView = '/GetTaskQueueForView',
    GetAllOrganizationUnitForLookupTable = '/GetAllOrganizationUnitForLookupTable',
    CreateOrEdit = '/CreateOrEdit',
    GetTaskQueueForEdit = '/GetTaskQueueForEdit'
}
export enum EXTRACTES_TABLES {
    ExtractedTables = '/ExtractedTables',
}
export enum EXTRACTES_TABLES_EndPoints {
    Getall = '/Getall',
    GetExtractedTableForView = '/GetExtractedTableForView',
}
export enum FORMS {
    Forms = '/Forms',
}
export enum FORMS_EndPoints {
    CreateOrEdit = '/CreateOrEdit',
    ImportFormJson = '/ImportFormJson',
    GetFormForEdit = '/GetFormForEdit',
    PublishForm = '/PublishForm',
    PublishFormToTrain = '/PublishFormToTrain',
    Delete = '/Delete',
    RetireForm = '/RetireForm',
    FormHistory = '/GetHistory',
    CreateFormVersion = '/CreateNewVersion',
    ExportFormFile = '/ExportToFile',
    TrainForm='/TrainForm'
}
export enum FORM_FIELDS {
    FormFields = '/FormFields',
}
export enum FORM_FIELDS_EndPoints {
    CreateOrEdit = '/CreateOrEdit',
    GetFormFieldForEdit = '/GetFormFieldForEdit',
    Delete = '/Delete',
    ExtractField = '/ExtractField',
    GetAll = '/GetAll',
    GetFormFieldChildrenForEdit = '/GetFormFieldChildrenForEdit',
    BulkCreateOrEdit = '/BulkCreateOrEdit',
    BulkCreate = '/BulkCreate',
    ExportFormFields ='/ExportFormFields'
}

export enum PIPELINES {
    Pipelines = '/Pipelines',
}
export enum PIPELINES_Endpoints {
    GetAll = '/GetAll',
    GetPipelineForView = '/GetPipelineForView',
    ImportPipeLine ='/ImportPipeline',
    ExportPipeline ='/ExportPipeline',
    createOrEdit ='/CreateOrEdit',
    GetPipelineForEdit = '/GetPipelineForEdit',
    delete = '/Delete'
}


export enum DocumentBatches {
    Batches = '/DocumentBatches',
}
export enum Batch_EndPoints {
    GetAll = '/GetAll',
    GetDocumentBatchForView = '/GetDocumentBatchForView',
    createOrEdit ='/CreateOrEdit',
    GetDocumentBatchForEdit='/GetDocumentBatchForEdit',
    Delete="/Delete",
    MultipleDelete='/MultipleDelete',
    DocumentBatchExports= '/DocumentBatchExports',
    LockUnlockDocumentBatch='/LockUnlockDocumentBatch',
    UpdateDocumentBatchStatus='/UpdateDocumentBatchStatus',
    CreateOrEdit='/CreateOrEdit',
    DownloadBatchFile='/DownloadBatchFile'
}

export enum DocumentBatchItems {
    DocumentBatchItems = '/DocumentBatchItems',
    AddTaskToBatch='/AddTaskToBatch'
}
export enum BatchItems_EndPoints {
    GetAll = '/GetAll', 
}

export enum LOOK_UPS {
    Lookups = '/Lookups',
}
export enum LOOK_UPS_EndPoints {
    GetAll = '/GetAll',
    GetLookup='/GetLookup'
}

export enum ORGANIZATION_UNIT {
    OrganizationUnit = '/OrganizationUnit',
}
export enum ORGANIZATION_UNIT_EndPoints {
    GetOrganizationUnits = '/GetOrganizationUnits',
    GetOrganizationUnitUsers = '/GetOrganizationUnitUsers',
    CreateOrganizationUnit = '/CreateOrganizationUnit',
    UpdateOrganizationUnit = '/UpdateOrganizationUnit',
    DeleteOrganizationUnit = '/DeleteOrganizationUnit',
    RemoveUserFromOrganizationUnit = '/RemoveUserFromOrganizationUnit',
    FindUsers = "/FindUsers",
    AddUsersToOrganizationUnit = "/AddUsersToOrganizationUnit"

}
export enum BALANCE_DEBITS {
    BalanceDebits = '/BalanceDebits',
}
export enum GET_BALANCE_DEBITS_EndPoints {
    GetBalanceDebitsGraph = '/GetBalanceDebitsGraph',
}
export enum DASHBOARD_WIDGETS {
    DashboardWidgets = '/DashboardWidgets',
}
export enum REPORTS {
    ActiveTenent = '/DocumentProcessingEngineService/GetActiveTenants',
    Individual = '/HumanTasks/GetIndividualTaskReport?',
    Template = '/DocumentProcessingEngineService/TemplatesReport?',
    AverageTime = '/HumanTasks/GetAverageTimeReport?',
    OrganizationUnits = '/OrganizationUnit/GetOrganizationUnits',
    PendingTask = '/DashBoardWidgets/GetPendingTasksByUser',
    TemplateLibraries = '/Forms/GetAll',
    TemplateLibrariesType = '/TemplateLibraries/GetAll',
    AllTaskByDate = '/HumanTasks/GetAllTaskByDate?date=',
    DeleteTemplate = '/TemplateLibraries/Delete',
}
export enum REPORTS_EXCEL {
    TemplateLibrariesExcel = '/TemplateLibraries/GetTemplateLibrariesToExcel?Filter=&NameFilter=&DescriptionFilter=',
    AverageTimeExport = '/HumanTasks/ExportAverageTimeReport?',
    IndividualExport = '/HumanTasks/ExportIndividualTaskReport',
    TemplatesExcel = '/DocumentProcessingEngineService/GetTemplatesReportToExcel?',
    GetBalanceDebitsToExcel = '/BalanceDebits/GetBalanceDebitsToExcel?MinTransactionDateTimeFilter=',
}
export enum DASHBOARD_WIDGETS_EndPoints {
    GetAgeOfPendingTasks = '/GetAgeOfPendingTasks',
    GetAgeOfCompletedTasks = '/GetAgeOfCompletedTasks',
    GetSubscriptionStatus = '/GetSubscriptionStatus',
    GetTaskCountByStatus = '/GetTaskCountByStatus',
    GetCompletedTasksByUser = '/GetCompletedTasksByUser',
    GetOrganizationStatus = '/GetOrganizationStatus',
}

export enum AUTH {
    Auth = '/Auth',
}
export enum AUTH_EndPoints {
    GetUserInfo = '/GetUserInfo',
}

export enum BILLING {
    Billing = '/Billing',
}
export enum USER_Subscription {
    Subscriptions = '/Subscriptions',
}

export enum BILLING_EndPoints {
    OrganizationBillingStatus = '/OrganizationBillingStatus',
}
export enum FIELD_VALIDATIONS {
    FieldValidations = '/FieldValidations',
}
export enum FIELD_VALIDATIONS_EndPoints {
    GetAll = '/GetAll',
}

export enum CASE_RECORDS {
    CaseRecords = '/CaseRecords',
}
export enum CASE_RECORDS_Types {
    CaseRecordType = '/CaseRecordTypes',
    CASERECORDSTypesGetHistory = '/GetHistory'
}
export enum CHECK_LIST {
    Checklists = '/Checklists'
}
export enum CHECK_LIST_ENDPOINT {
    AttachToCase = '/AttachToCase',
    UpdateCaseListFromCase = '/UpdateCaseListFromCase'
}
export enum CHECK_LIST_TEMPLATE {
    ChecklistTemplates = '/ChecklistTemplates'
}

export enum CHECK_LIST_TEMPLATE_ENDPOINT {
    GetAll = '/GetAll',
    GetChecklistTemplateForView = '/GetChecklistTemplateForView'
}
export enum CASE_RECORDS_EndPoints {
    GetFormLayout = '/GetFormLayout',
    GetCaseRecordForEdit = '/GetCaseRecordTypeForEdit',
    CreateOrEdit = '/CreateOrEdit',
    GetAllInternalCases = '/GetAllInternalCases',
    DeleteInternalCases = '/Delete',
}

export enum ABP_USERConfiguration {
    AbpUserConfiguration = '/AbpUserConfiguration',
}
export enum ABP_USERConfiguration_EndPoints {
    GetAll = '/GetAll',
}
export enum Lighthouse_EndPoint {
    userInfo = 'Auth/GetUserInfo',
    billing = 'Billing/OrganizationBillingStatus',
    menu = 'FunctionCategory/menu',
    // category ='FunctionCategory',
    // definition='FunctionDefinition',
    // version='FunctionVersion',
    // configration='FunctionConfiguration'
    // menu='FunctionCategories/menu',
    category = 'FunctionCategory',
    definition = 'FunctionDefinition',
    version = 'FunctionVersion',
    configration = 'FunctionConfiguration',
}
export enum EXTRACTED_FEATURE {
    ExtractedFeature = '/ExtractedFeature',
}
export enum EXTRACTED_FEATURE_EndPoint {
    GetAll = '/GetAll',
    GetBusinessEvents = '/GetBusinessEvents',
    GetFeaturesCount = '/GetFeaturesCount',
    GetKeyInformation ='/GetKeyInformation'
}

export enum BALANCE_CREDITS {
    BalanceCredits = '/BalanceCredits'
}
export enum BALANCE_CREDITS_ENDPOINTS {
    GetAll = '/GetAll',
    GetBalanceReport = '/GetBalanceReport',
    GetBalanceCreditForView = '/GetBalanceCreditForView',
    GetBalanceCreditForEdit = '/GetBalanceCreditForEdit',
    CreateOrEdit = '/CreateOrEdit',
    Delete = '/Delete',
    GetBalanceCreditsToExcel = '/GetBalanceCreditsToExcel',

}

export enum CASE_RECORD_TYPES {
    CaseRecordTypes = '/CaseRecordTypes'
}
export enum Events {
    Event = '/Events'
}

export enum EventsQueue {
    EventQueues = '/EventQueues'
}
export enum Events_EndPoints {
    GetAll = '/GetAll',
    CreateOrEdit= "/CreateOrEdit",
    Delete = '/Delete',
    GetEventForEdit = '/GetEventForEdit',
    CreateNewVersion ='/CreateNewVersion',
    PublishEvent='/PublishEvent',
    RetireEvent = '/RetireEvent',
    GetHistory = '/GetHistory'

}
export enum CASE_RECORD_TYPES_ENDPOINTS {
    GetAll = '/GetAll',
    CreateOrEdit= "/CreateOrEdit",
    caseRecordEdit="GetCaseRecordTypeForEdit",
    Delete = '/Delete',
    CreateNewVersion ='/CreateNewVersion',
    PublishCaseType ='/PublishCaseType',
    RetireCaseType = '/RetireCaseType'
}
export enum CASE_RECORD_FIELDSES {
    CaseRecordFieldses = '/CaseRecordFieldses'
}

export enum CASE_RECORD_FIELDSES_ENDPOINTS {
    GetAll = '/GetAll',
    ChangeDisplayOrder="/ChangeDisplayOrder",
    CreateOrEdit= "/CreateOrEdit",
    GetPendingCaseFields='/GetPendingCaseFields',
}
export enum CASE_RECORD_FIELDS_CUSTOMIZATIONSES {
    CaseRecordFieldsCustomizationses = '/CaseRecordFieldsCustomizationses'
}

export enum CASE_RECORD_FIELDS_CUSTOMIZATIONSES_ENDPOINTS {
    GetAll = '/GetAll',
    ChangeDisplayOrder="/ChangeDisplayOrder",
    CreateOrEdit= "/CreateOrEdit",
    Delete = '/Delete'
}
