import { Breadcrumb } from './breadcrumb/breadcrumb.component';
import { AccountUsageComponent } from './../reports/components/accountUsage/accountUsage.component';
import { Inject, Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { Router } from '@angular/router';
import { __Permissions } from '../core/permissionEnum/permissions';
import { LighthouseService } from '../shared/services/lighthouse.service';
import { FunctionsComponent } from '../functions/functions.component';
import { MenuItem } from 'primeng/api';
import { map } from 'rxjs';
import { Lighthouse_EndPoint } from '../EndPointUrls/apiEndPoints.enum';
import { ILightHouseMenuItem } from '../document/interfaces/ILightHouseMenuItem';
import { data } from 'jquery';
import { BreadCrumbService } from '../core/services/breadcrumb.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styles: [``],
})
export class AppMenuComponent implements OnInit {
    allFunctionList: any[];
    allFunctionListDeail: any[];
    model: any[] = [];
    ContributorMenu: any[] = [];
    AdminMenu: any[] = [];
    DeveloperMenu: any[] = [];
    hasContributorPermission: boolean = false;
    selectedMenu: string = '';
    hasAdminPermission: boolean = false;
    hasDeveloperPermission: boolean = false;
    totalFunctions: any;
    constructor(
        public authService: AuthService,
        private router: Router,
        public service: LighthouseService,
        @Inject(BreadCrumbService) private breadcrumbService: BreadCrumbService
    ) {}
    
    contributorPermissions = [
        __Permissions.document_create,
        __Permissions.documentEdit,
        __Permissions.document_delete,
        __Permissions.documentList,
        __Permissions.select_Case,
    ];
    adminPermissions = [
        __Permissions.templateCreate,
        __Permissions.templateEdit,
        __Permissions.templates_Delete,
        __Permissions.createPipeline,
        __Permissions.reports,
        __Permissions.templateLibraries,
        __Permissions.taskQueue,
        __Permissions.createTemplateLibrary,
        __Permissions.createTaskQueue,
        __Permissions.OrganizationUnits,
        __Permissions.createRoles,
        __Permissions.users,
        __Permissions.CaseRecordTypesCreate   
     ];
    developerPermissions = [__Permissions.function, __Permissions.functionCall];
    ngOnInit() {
        this.checkForSideNavPermission();
            (this.ContributorMenu = [
                {
                    // label: 'Home',
                    items: [
                        {
                            visible: this.authService.hasPermission(
                                __Permissions.document_create,
                                false
                            ),
                            label: 'Upload Documents',
                            routerLink: ['/document/create'],
                            class: 'upload-doc',
                            data: { breadcrumb: 'Upload Documents' }
                        },
                        {
                            visible: this.authService.hasPermission(
                                __Permissions.documentList,
                                false
                            ),
                            label: 'Processing Status',
                            routerLink: ['/document/Processing'],
                            class: 'upload-doc process-list',
                            data: { breadcrumb: 'Processing Status' }
                        },
                        {
                            visible: this.authService.hasPermission(
                                __Permissions.documentList,
                                false
                            ),
                            label: 'Documents',
                            routerLink: ['/document/list'],
                            class: 'upload-doc doc-list',
                            data: { breadcrumb: 'Documents' }   
                        },
                        {
                            visible: this.authService.hasPermission(
                                __Permissions.batchCreate,
                                false
                            ),
                            label: 'Batches',
                            routerLink: ['/batch/list'],
                            class: 'upload-doc doc-list',
                            data: { breadcrumb: 'Batches' }   
                        },
                        // {
                        //     visible: this.authService.hasPermission(
                        //         __Permissions.select_Case,
                        //         false
                        //     ),
                        //     label: 'Case Management',
                        //     routerLink: ['/case/caselist'],
                        //     class: 'upload-doc case-list',
                        // },
                    ],
                },
            ]);
        this.AdminMenu = [
            {
                icon: 'pi pi-user',
                label: 'Administration',
                data: { breadcrumb: 'Administration' } ,
                items: [
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.reports,
                            false
                        ),
                        label: 'Reports',
                        class: 'upload-doc reportIcon',
                        routerLink: ['/reports'],
                        data: { breadcrumb: 'Reports' } 
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.templateCreate,
                            false
                        ),
                        label: 'Templates',
                        class: 'upload-doc templateIcon',
                        routerLink: ['/administration/templateLibraries'],
                        data: { breadcrumb: 'Templates' } 
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.createTemplateLibrary,
                            false
                        ),
                        label: 'Template Libraries',
                        class: 'upload-doc templateIcon',
                        routerLink: ['/administration/libraries'],
                        data: { breadcrumb: 'Template Libraries' } 
                    },
                    // {
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.createCheckListTemplateItem,
                    //         false
                    //     ),
                    //     label: 'Checklist Template',
                    //     class: 'upload-doc checklistIcon',
                    //     routerLink: ['/administration/checklist'],
                    // },
                    // {
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.ChecklistTemplateItems,
                    //         false
                    //     ),
                    //     label: 'Audit Logs',
                    //     class: 'upload-doc checklistIcon',
                    //     routerLink: ['/administration/audit-logs'],
                    // },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.users,
                            false
                        ),
                        label: 'Users',
                        class: 'upload-doc userIcon',
                        routerLink: ['/administration/user'],
                    },
                    // {
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.createRoles,
                    //         false
                    //     ),
                    //     label: 'Roles',
                    //     class: 'upload-doc roleIcon',
                    //     routerLink: ['/administration/roles'],
                    // },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.createPipeline,
                            false
                        ),
                        label: 'Pipelines',
                        class: 'upload-doc pipelineIcon',
                        routerLink: ['/administration/pipelines'],
                        data: { breadcrumb: 'Pipelines' } 
                    },
                    {
                        visible: this.authService.hasPermission(
                            __Permissions.createTaskQueue,
                            false
                        ),
                        label: 'Task Queues',
                        class: 'upload-doc queIcon',
                        routerLink: ['/administration/taskQueue'],
                        data: { breadcrumb: 'Task Queues' } 
                    },
                    // {

                    //     visible:this.authService.hasPermission(__Permissions.BalanceCredits,false),
                    //     label: 'Account Balance',
                    //     icon: 'pi pi-folder',
                    //     routerLink: ['/administration/balancecredits'],
                    // },

                    // {
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.CaseRecordTypesCreate,
                    //         false
                    //     ),
                    //     label: 'Case Types',
                    //     class: 'upload-doc caseRecordIcon',
                    //     routerLink: ['/administration/casetype'],
                    //     data: { breadcrumb: 'Case Type' } 
                    // },
                    // {
                    //     //TODO change to  account balance permissions
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.CaseRecords,
                    //         false
                    //     ),
                    //     label: 'Case Records',
                    //     class: 'upload-doc caseRecordIcon',
                    //     routerLink: ['/administration/casefields'],
                    // },
                    // {
                    //     //TODO change to  account balance permissions
                    //     visible: this.authService.hasPermission(
                    //         __Permissions.caseRecordFieldsCustomizationses,
                    //         false
                    //     ),
                    //     label: 'Custom Case Records Fields',
                    //     class: 'upload-doc customFieldIcon',
                    //     routerLink: ['/administration/customcasefields'],
                    // },
                    {
                        //TODO change to  account balance permissions
                        visible: this.authService.hasPermission(
                            __Permissions.OrganizationUnits,
                            false
                        ),
                        label: 'Teams',
                        class: 'upload-doc teamsIcon',
                        routerLink: ['/administration/team'],
                        data: { breadcrumb: 'Teams' } 
                    },
                    {
                        //TODO change to  account balance permissions
                        // visible: this.authService.hasPermission(
                        //     __Permissions.OrganizationUnits,
                        //     false
                        // ),
                        label: 'Events',
                        class: 'upload-doc teamsIcon',
                        routerLink: ['/administration/event'],
                        data: { breadcrumb: 'Teams' } 
                    },
                ],
            },
        ];
        // this.model = this.AdminMenu
        if (this.hasContributorPermission) {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Contributor', route: ''},
              ]);

            this.selectedMenu = 'contributor';
            this.model = this.ContributorMenu;
        } else if (this.hasAdminPermission) {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Administration', route: ''},
              ]);
            this.selectedMenu = 'admin';
            this.model = this.AdminMenu;
        } else {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Developer', route: ''},
              ]);
            this.selectedMenu = 'developer';
            this.model = this.DeveloperMenu;
        }

        this.selectedActivetab();
    }
    SelectRole(role: string) {

        this.model= []
        this.breadcrumbService.setBreadcrumbs([
        ]);
        if (role == 'contributor') {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Contributor', route: ''},
              ]);
            this.selectedMenu = 'contributor';
            this.model = this.ContributorMenu;
        } else if (role == 'admin') {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Administration', route: ''},
              ]);
            this.selectedMenu = 'admin';
            console.log(this.AdminMenu)
            this.model = this.AdminMenu;
        } else {
            this.breadcrumbService.setBreadcrumbs([
                { label: '', route: '' },
                { label: 'Developer', route: ''},
              ]);
            this.selectedMenu = 'developer';
            this.DeveloperMenu = [
                {
                    icon: 'pi pi-user',
                    label: 'Functions',
                    command: () => this.navigateToFunction(),
                    items: [
                        {
                            visible: this.authService.hasPermission( __Permissions.function),
                            label: 'Functions',
                            menuType: 'functions',
                            command: () => this.navigateToFunction(),
                            class: 'upload-doc functioNIcon',
                            data: { breadcrumb: 'Functions' },
                            items : []
                        },
                        // {
                        //     // icon: 'pi pi-user',
                        //     visible: this.authService.hasPermission( __Permissions.function),
                        //     menuType: 'functions',
                        //     label: 'Automation',
                        //     routerLink: ['/automation'],
                        //     data: { breadcrumb: 'Teams' } 
                        // },
                    ],
                }
            ];

            this.service
                .get_withFullUrl(this.service.apiurl + Lighthouse_EndPoint.menu)
                .subscribe((menu: ILightHouseMenuItem[]) => {
                    for (const menuItem of menu) {
                        this.DeveloperMenu[0].items[0].items.push({
                            label: menuItem.name,
                            icon: menuItem.icon ,
                            class:'functioniconclas',
                            menuType: 'functions',
                            command: () => this.findData(menuItem),
                        });
                    }
                    this.allFunctionList = menu;
                    this.model = this.DeveloperMenu;
                });
        }
        // if (this.authService.selectedUserRole.roleId == 1 && role.roleId != 1) {
        //     // if admin and try to navigate to other roles then do nothing
        //     // act like disable
        // } else {
        // this.authService.selectedUserRole = role;
        // if (this.authService.selectedUserRole.roleId == 3) {
        //     this.router.navigate(['/functions']).then( ()=>
        //         this.authService.loadApp(true)
        //     )
        //     // this.authService.loadApp(true)
        // }
        // else if(this.authService.selectedUserRole.roleId == 2) {

        //     this.router.navigate(['/document/create']).then(
        //         ()=>{
        //             this.authService.loadApp(true)
        //         }
        //     )
        // }
        // else {
        //     this.router.navigate(['/administration/templateLibraries']).then(
        //         ()=> {
        //             this.authService.loadApp(true)
        //         }
        //     )
        // }
    }
    // }

    checkForSideNavPermission() {

        // let contributorCount = 0
        this.contributorPermissions.forEach((element) => {
            if (this.authService.hasPermission(element, false)) {
                this.hasContributorPermission = true;
                // console.log(element);
            }
        });

        this.adminPermissions.forEach((element) => {
            if (this.authService.hasPermission(element, false)) {
                this.hasAdminPermission = true;
                // console.log(element);
            }
        });

        this.developerPermissions.forEach((element) => {
            if (this.authService.hasPermission(element)) {
                this.hasDeveloperPermission = true;
                // console.log(element);
            }
        });

        if (
            !this.hasDeveloperPermission &&
            !this.hasAdminPermission &&
            !this.hasContributorPermission
        ) {
            this.router.navigate(['/no-access']);
        }
    }
    selectedActivetab() {
        
        this.ContributorMenu[0].items.forEach((menu: any) => {
            if (window.location.href.includes(menu.routerLink[0])) {
                this.SelectRole('contributor');
            }
        });
        this.AdminMenu[0].items.forEach((menu: any) => {
            if (window.location.href.includes(menu.routerLink[0])) {
                this.SelectRole('admin');
            }
        });
        this.DeveloperMenu[0].items.forEach((menu: any) => {
            if (window.location.href.includes(menu.routerLink[0])) {
                this.SelectRole('developer');
            }
        });
    }

    navigateToFunction(): void {
        this.router.navigate(['/functions']);
    }
    findData(item: any) {
        const dataToDisplay = this.getDataForItem(item);
        this.allFunctionListDeail = dataToDisplay;
        this.service.filterData(this.allFunctionListDeail);
    }
    getDataForItem(item: any) {
        return this.allFunctionList.find(
            (dataItem) => dataItem.name === item.name
        );
    }
}
