import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { AppErrorHandler } from './core/AppErrorHandler';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './core/customUrlSerializer/customUrlSerializer';
import { AuthService } from './core/services/auth.service';
import { LogOutComponent } from './log-out/log-out.component';
import { PermissionGuard } from './core/guards/permission.guard';
import { ToastrService } from 'ngx-toastr';
import { BreadCrumbService } from './core/services/breadcrumb.service';

import { MsalModule, MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent, MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig, protectedResources, loginRequest } from './auth-config';


export function initializeApp(auth: AuthService) {
    return () => {
        auth.handleAzureB2CLogin()
    }
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
  }
  
  /**
  * MSAL Angular will automatically retrieve tokens for resources
  * added to protectedResourceMap. For more info, visit:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
  */
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
  
    protectedResourceMap.set(protectedResources.apiTodoList.endpoint, [
        {
            httpMethod: 'GET',
            scopes: [...protectedResources.apiTodoList.scopes.read]
        },
        {
            httpMethod: 'POST',
            scopes: [...protectedResources.apiTodoList.scopes.write]
        },
        {
            httpMethod: 'PUT',
            scopes: [...protectedResources.apiTodoList.scopes.write]
        },
        {
            httpMethod: 'DELETE',
            scopes: [...protectedResources.apiTodoList.scopes.write]
        }
    ]);
  
    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap,
    };
  }
  
  /**
  * Set your default interaction type for MSALGuard here. If you have any
  * additional scopes you want the user to consent upon login, add them here as well.
  */
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
  }

@NgModule({
    declarations: [AppComponent, LogOutComponent],
    imports: [
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        CoreModule,
        AppLayoutModule,
        MsalModule,
        BrowserAnimationsModule,
    ],
    providers: [
        AuthService,
        PermissionGuard,
        ToastrService,
        BreadCrumbService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AuthService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: ErrorHandler, useClass: AppErrorHandler },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
