import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { ChangeDetectorRef, Inject, Injectable, Injector } from "@angular/core";
import { finalize, Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { LoaderService } from "../services/loader.service";
@Injectable()
export class AppInterceptor implements HttpInterceptor {
requestCounter = 0;// for loader
constructor(@Inject(Injector) private injector: Injector, private authService: AuthService,public loader:LoaderService ) {
}
intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestCounter++;
    this.showLoader(request);
    //  if (this.authService.getAuthToken()) {
      request=this.setHeaders(request);
    // }
    return next.handle(request)
    .pipe(finalize(
        () => {
            this.requestCounter--;
            if (this.requestCounter == 0) {
               this.hideLoader();
            }
        }));
}
  showLoader(req:HttpRequest<any>)
  {
    if (!(
            req.headers.has('noloader') ||
            req.url.includes("/connect/token") ||
            req.url.includes("GetStatus") ||
            req.url.includes("/Chat")

        )
    ) {
        this.loader.show();
    }
  }
  hideLoader()
  {
    this.loader.hide();
  }
  setHeaders(request:HttpRequest<any>)
  {
    if(this.authService.loggedInUser?.activeTenantKey==undefined)
    {
        request = request.clone({
            setHeaders: {
              'Authorization': `Bearer ${localStorage.getItem('id_token')}`,
          }
         });
    }
    else{
        request = request.clone({
            setHeaders: {
              'Authorization': `Bearer ${localStorage.getItem('id_token')}`,
              'tenantkey':`${this.authService.loggedInUser?.activeTenantKey}`,
          }
         });
    }
     return request;
  }
  }
