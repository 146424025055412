
  <div class="container body">
    <div class="row" style="margin-top: 55px;">
      <div class="col-md-6 col-lg-6">
        <div class="whiteBk">
          <div class="loginFormInner">
            <h1>OpenBots DocumentAI requires you to have an Active Subscription</h1>
            <p>
              <a href="https://openbots.ai/">www.openbots.ai</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6">

      </div>
    </div>
    <div class="col-md-12 col-lg-12 footerPnl">
    </div>
  </div>
