export enum __Permissions {
    EmailAccounts_Read='openbots.server.Infrastructure.EmailAccounts-Read',
    documentList='Pages.HumanTasks',
    document_create ='Pages.HumanTasks.Create',
    document_delete ='Pages.HumanTasks.Delete',
    documentEdit='Pages.HumanTasks.Edit',
    select_Case='Pages.CaseRecords',
    templates_Delete ='Pages.Administration.Forms.Delete',
    templateLibraries='Pages.Administration.TemplateLibraries',
    createTemplateLibrary ='Pages.Administration.TemplateLibraries.Create',
    templateCreate='Pages.Administration.Forms.Create',
    templateEdit='Pages.Administration.Forms.Edit',
    function='Pages.Functions',
    functionCall='Pages.Functions.Call',
    pipelines='Pages.Pipelines',
    createPipeline ='Pages.Pipelines.Create',
    reports='Pages.Reports',
    formFields='FormFields',
    fieldCreate='Pages.Administration.FormFields.Create',
    fieldDelete='Pages.Administration.FormFields.Delete',
    fieldEdit='Pages.Administration.FormFields.Edit',
    deleteTaskQueue='Pages.Administration.TaskQueues.Delete',
    editTaskQueue='Pages.Administration.TaskQueues.Edit',
    createTaskQueue='Pages.Administration.TaskQueues.Create',
    taskQueue='Pages.Administration.TaskQueues',
    OrganizationUnits='Pages.Administration.OrganizationUnits',
    ManageMembers='Pages.Administration.OrganizationUnits.ManageMembers',
    ManageOrganizationTree='Pages.Administration.OrganizationUnits.ManageOrganizationTree',
    ManageRoles='Pages.Administration.OrganizationUnits.ManageRoles',
    BalanceCredits='Pages.Administration.BalanceCredits',
    ChecklistTemplateItems='Pages.Administration.ChecklistTemplateItems',
    createCheckListTemplateItem='Pages.Administration.ChecklistTemplateItems.Create',
    deleteCheckListTemplateItem='Pages.Administration.ChecklistTemplateItems.Delete',
    editChecklistTemplateItem='Pages.Administration.ChecklistTemplateItems.Edit',
    checklistItemCreate='Pages.Administration.ChecklistItems.Create',
    deleteChecklistItem='Pages.Administration.ChecklistItems.Delete',
    editCheckListItem='Pages.Administration.ChecklistItems.Edit',
    CaseRecords ='Pages.CaseRecords',
    createCaseRecord = 'Pages.CaseRecords.Create',
    deleteCaseRecord ='Pages.CaseRecords.Delete',
    editCaseRecord ='Pages.CaseRecords.Edit',
    caseRecordFieldsCustomizationses='Pages.CaseRecordFieldsCustomizationses',
    createCaseRecordFieldsCustomizationses='Pages.CaseRecordFieldsCustomizationses.Create',
    deleteCaseRecordFieldsCustomizationses='Pages.CaseRecordFieldsCustomizationses.Delete',
    editCaseRecordFieldsCustomizationses='Pages.CaseRecordFieldsCustomizationses.Edit',
    createRoles ='Pages.Administration.Roles',
    batchMenuGrid ='Pages.DocumentBatches',
    batchCreate ='Pages.DocumentBatches.Create',
    batchEdit ='Pages.DocumentBatches.Edit',
    batchDelete ='Pages.DocumentBatches.Delete',
    users ='Pages.Administration.Users',
    UsersChangePermission ='Pages.Administration.Users.ChangePermissions',
    CaseRecordTypesCreate='Pages.CaseRecordTypes.Create',
    CaseRecordTypesEdit ='Pages.CaseRecordTypes.Edit' ,
    CaseRecordTypesDelete ='Pages.CaseRecordTypes.Delete'
}