import { Component, OnInit, EventEmitter, ViewChild, Input, SimpleChange, OnChanges, SimpleChanges, Output } from '@angular/core';
import {
    UploadInput,
    UploaderOptions,
    UploadOutput,
} from "ngx-uploader";
import { Subscription, Observable, throwError } from 'rxjs';
import { HttpEvent, HttpEventType, HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
@Component({
    selector: 'tX-fileUploader',
    templateUrl: './fileUploader.component.html',
    styleUrls: ['./fileUploader.component.css']
})
export class FileUploaderComponent implements OnInit, OnChanges {
    @Input() ariaDisabled?:boolean;
    options: UploaderOptions;
    uploadInput: EventEmitter<UploadInput>;
    dragOver: boolean=false;
    files: File[];
    alreadyUploading = false;
    docUploadError = false;
    subscription: Subscription;
    progress: number = 0;
    documents :any[]= [];
    showUploader: boolean;
    @Input() API?: string;
    @Input() data?: any;

    show:boolean=true;

    @Input('uploadOption')
    set uploadOption(value: UploaderOptions) {
        this.options = value;
    }
    @Input() pageName:string ='Normal'
    @Output() onUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDeleteFile: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("myFileInput", { static: false }) myFileInput: any;

    constructor(public http: HttpClient, public toastr: ToastrService) {
        this.options = UploadOptions;
        this.showUploader = true;
        this.files = []; // local uploading files array
        this.uploadInput = new EventEmitter<UploadInput>();
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        // Extract changes to the input property by its name
        let change: SimpleChange = changes['data'];
        // Whenever the data in the parent changes, this method gets triggered. You
        // can act on the changes here. You will have both the previous value and the
        // current value here.
    }
    onUploadOutput(output: UploadOutput): void {
        if(output.file){
            if(!this.options.allowedContentTypes.includes(output.file.type))
            {
              this.toastr.error("This File type is not allowed!");
            }
            else{
                this.myFileInput.nativeElement.value = "";
                if(output.type!='rejected')
                {
                    this.onUpload.emit(output);
                }
                else
                {
                    if (typeof output.file !== "undefined") {
                        this.docUploadError = false;
                        let fileName: string = output.file.name;
                        if (
                            fileName.includes(".exe") ||
                            fileName.includes(".bat") ||
                            fileName.includes(".com") ||
                            fileName.includes(".vbs")
                        ) {
                            this.toastr.error("This File type is not allowed!");
                            return;
                        }
                        if (output.file.size > this.options.maxFileSize && this.pageName =='Normal') {
                            this.toastr.error("Total file size exceeds the maximum limit of 25 MB.")
                        }else if(this.pageName =='uploadbatch'){
                            //this flag is use for batch upload
                            output.type='addedToQueue'
                            this.onUpload.emit(output);
                        }
                        // this.toastr.error("This File type is not allowed!");
                    }
                }
            }
        }

       
    }
    DeleteFileFromList(del_file: File) {
        this.show=false;
        this.files = this.files.filter(file => file !== del_file);

        if (this.files.length <= this.options.maxUploads) {
            this.showUploader = true;
        }
        this.options.maxUploads=this.options.maxUploads+1;
        this.options={...this.options};
        // setTimeout(() => {
        // this.show=true;
        // }, 2000);

        this.onDeleteFile.emit(del_file)
    }
    cancel() {
        this.subscription.unsubscribe();
    }
    startUpload(): void {
        this.subscription = this.CallDocumentAPi(this.files)
            .subscribe(
                (event: HttpEvent<any>) => {
                    switch (event.type) {
                        case HttpEventType.Sent:
                            this.alreadyUploading = true;
                            break;
                        case HttpEventType.ResponseHeader:
                            break;
                        case HttpEventType.UploadProgress:
                            this.progress = Math.round((event.loaded / event.total) * 100);
                            break;
                        case HttpEventType.Response:
                            if (event.body) {
                                this.documents = [...event.body.items];
                                this.files = [];
                                this.alreadyUploading = false;
                            }
                            setTimeout(() => {
                                this.progress = 0;
                            }, 1500);
                    }
                },
                error => {
                    // this.toastr.error('Something went wrong!');
                    this.docUploadError = true;
                    this.alreadyUploading = false;
                }
            );
    }
    CallDocumentAPi(files: any): Observable<any> {
        let headerData = new HttpHeaders({ 'Content-Type': 'application/json' });

        const formData = new FormData();
        if (this.data != undefined) {
            formData.append('data', JSON.stringify(this.data));
        }
        this.files.forEach(file => {
            formData.append('files', file, file.name);
        });

        return this.http.post<any>(this.API, formData, { headers: headerData })
            .pipe(catchError((error) => { return throwError(error); }));
    }
}
export const UploadOptions = {
    concurrency: 1,
    maxUploads: 1000,
    maxFileSize: 26214400,
    allowedContentTypes: ["application/pdf", "image/png", ".tiff", "image/jpg", "image/jpeg", "zip", "rar", "application/x-zip-compressed"]
}
